<template>
    <div class="component-box">
        <div class="pb-4">
            <p class="text-xs pb-2 font-semibold">
                {{ $t('common.campaign') }}
            </p>
            <div class="text-xs flex flex-col space-y-1">
                <p>
                    <span class="text-desc-text">
                        {{ $t('page.campaign.campaign_name') }}
                    </span>
                    :
                    {{ infor_campaign.campaign_name }}
                </p>
                <p>
                    <span class="text-desc-text">
                        {{ $t('page.campaign.objective_type') }}</span
                    >
                    :
                    {{ p__formatObjectiveType(infor_campaign.objective_type) }}
                </p>
                <p>
                    <span class="text-desc-text">
                        {{ $t('common.budget') }}
                    </span>
                    :
                    {{
                        p__renderMoneyByCurrency(
                            infor_campaign.budget,
                            currency
                        )
                    }}
                </p>
            </div>
        </div>

        <div class="pb-2">
            <p class="text-xs font-semibold">
                {{ $t('page.campaign.adgroup_list') }}
            </p>
            <p class="text-xs text-desc-text italic">
                *
                {{
                    $t('page.campaign.desc_max_create_xxx_adgroup', {
                        xxx: max_adgroup
                    })
                }}
            </p>
        </div>

        <div class="text-xs">
            <div class="flex">
                <p class="text-desc-text">
                    {{ $t('page.campaign.total_daily_budget') }}
                </p>
                <p class="ml-auto">
                    {{ p__renderMoneyByCurrency(total_daily_budget, currency) }}
                </p>
            </div>
            <div class="flex">
                <p class="text-desc-text">
                    {{ $t('page.campaign.total_lifetime_budget') }}
                </p>
                <p class="ml-auto">
                    {{
                        p__renderMoneyByCurrency(
                            total_infinite_budget,
                            currency
                        )
                    }}
                </p>
            </div>
        </div>

        <ul class="flex flex-wrap -ml-2 mt-2">
            <li v-for="(item, index) in items" :key="index" class="ml-2 mt-2">
                <div class="relative">
                    <div
                        class="w-11 h-11 util-flex-center rounded-lg cursor-pointer border-[1px]"
                        :class="[
                            item.extension.id === selected_item.extension.id
                                ? 'text-white bg-brand-atosa--light'
                                : 'border-border'
                        ]"
                        @click="selectAdgroupItem(index)"
                    >
                        <p class="text-sm">
                            {{ index + 1 }}
                        </p>
                    </div>
                    <div
                        v-if="items.length > 1"
                        class="absolute -right-1 -top-2 rounded-full bg-white util-flex-center hover:shadow-lg w-5 h-5 shadow border-border border-[1px] cursor-pointer"
                        @click="handleRemoveItem(index)"
                    >
                        <i class="el-icon-close text-xs text-black"></i>
                    </div>
                </div>
            </li>
            <div v-if="main_package.package_data.name !== 'Atame Ads Free'">
                <li v-if="items_in_line.length < max_adgroup" class="ml-2 mt-2">
                    <div
                        class="w-11 h-11 util-flex-center rounded-lg hover:border-brand-atosa--light border-border border-[1px] cursor-pointer shadow-sm"
                        @click="handleCreateNewItem"
                    >
                        <i class="text-sm el-icon-plus"></i>
                    </div>
                </li>
            </div>

        </ul>

        <PreviewAdgroup
            :selected_item="selected_item"
            :currency="currency"
        ></PreviewAdgroup>
    </div>
</template>

<script>
import moment from 'moment'

export default {
    props: ['items', 'selected_item', 'currency', 'infor_campaign'],

    data() {
        return {
            max_adgroup: 15
        }
    },

    computed: {
        items_in_line: {
            get: function () {
                return this.items
            },
            set: function (val) {
                this.$emit('update:items', val)
            }
        },

        selected_item_inline: {
            get: function () {
                return this.selected_item
            },
            set: function (val) {
                this.$emit('update:selected_item', val)
            }
        },

        total_daily_budget() {
            let result = 0

            this.items_in_line.forEach((item) => {
                if (item.budget_mode === 'BUDGET_MODE_DAY') {
                    result += +item.budget
                }
            })

            return result
        },

        total_infinite_budget() {
            let result = 0

            this.items_in_line.forEach((item) => {
                if (item.budget_mode === 'BUDGET_MODE_INFINITE') {
                    result += +item.budget
                }
            })

            return result
        },

        main_package() {
            return this.$store.getters.currentPackage.s__main_package
        }
    },

    beforeMount() {
        this.p__event_bus.$on(
            'eb_clone_adgroup_with_new_attr',
            this.handleCloneItemWithNewAttributes
        )
    },

    destroyed() {
        this.p__event_bus.$off(
            'eb_clone_adgroup_with_new_attr',
            this.handleCloneItemWithNewAttributes
        )
    },

    methods: {
        handleCloneItemWithNewAttributes(attr) {
            if (this.items_in_line.length === this.max_adgroup) {
                return
            }
            const date = moment().format('HH:mm DD-MM-YYYY')

            const new_item = JSON.parse(
                JSON.stringify({
                    ...this.selected_item_inline,
                    ...attr
                })
            )
            new_item.extension.id = this.p__renderId()
            new_item.adgroup_name = `${this.$t('common.adgroup')}_${date}`

            this.items_in_line = [...this.items_in_line, new_item]
        },

        selectAdgroupItem(index) {
            this.selected_item_inline = this.items_in_line[index]

            // const view_layout = document.getElementById('view_layout')
            // view_layout.scrollTop = 0
        },

        handleRemoveItem(index) {
            if (this.items_in_line.length === 1) {
                return
            }

            if (
                this.selected_item_inline.extension.id ===
                this.items_in_line[index].extension.id
            ) {
                this.selectAdgroupItem(0)
            }
            this.items_in_line.splice(index, 1)
        },

        handleCreateNewItem() {
            if (this.items_in_line.length === this.max_adgroup) {
                return
            }
            const date = moment().format('HH:mm DD-MM-YYYY')

            const new_item = JSON.parse(
                JSON.stringify(this.selected_item_inline)
            )
            new_item.extension.id = this.p__renderId()
            new_item.adgroup_name = `${this.$t('common.adgroup')}_${date}`
            new_item.extension.must_change_ad = true

            this.items_in_line = [...this.items_in_line, new_item]
        }
    }
}
</script>
